.reject-portal {
  .profile-image {
    width: 150px;
    height: 150px;
    overflow: hidden;
    margin: 20px auto;
    margin-top: 50px;
    img {
      width: 100%;
      border-radius: 10px;
      height: 100%;
      object-fit: fill;
    }
  }
  .card-image {
    width: 310px;
    height: 200px;
    overflow: hidden;
    margin: 20px auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  figcaption {
    text-align: center;
  }
  .image-button {
    border: 0;
    margin: 0 auto;
    display: block;
    height: 60px;
    box-shadow: none;
    img {
      width: 50px;
    }
  }
  .reject-info {
    padding: 0 50px;
    p {
      color: #414141;
      line-height: 30px;
      font-size: 17px;
      text-align: justify;
    }
    textarea {
      width: 100%;
      resize: none;
      min-height: 200px;
      padding: 10px;
      border-radius: 10px;
      font-family: "g";
      font-size: 16px;
      line-height: 19px;
    }
    .upload-file {
      padding: 20px 0;
      span {
        margin-right: 20px;
      }
    }
  }

  .reject-value {
    font-size: 16px;
    margin-left: 15px !important;
  }
  .reject-divider {
    &::before {
      width: 0 !important;
    }
    .ant-divider-inner-text {
      padding-left: 0;
    }
  }
}
