.login {
  .left-wrapper {
    padding: 20px;
    padding-right: 0;
    .login-info-wrapper {
      background: #071d33;
      border-radius: 14px;
      width: 100%;
      min-height: calc(100vh - 50px);
      padding: 20px;
      box-sizing: border-box;
      position: relative;
      h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 38px;
        line-height: 43px;
        color: #ffffff;
        margin: 50px 0 20px;
        padding: 0;
      }
      h6 {
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 22px;
        color: #ffffff;
        opacity: 0.8;
        padding: 0;
        margin: 0;
        font-family: "gL" !important;
      }
      .news-info {
        margin-bottom: 65px;
        ul {
          padding: 0;
          margin: 0;
          margin-top: 50px;
          text-align: center;
          li {
            display: inline-flex;
            margin: 0 10px;
            a {
              display: block;
              text-decoration: none;
              transition: transform 0.2s ease;
              &:hover {
                transform: scale(1.05);
              }
              .thumbnail {
                min-height: 250px;
                overflow: hidden;
                background-color: #fff;
                padding: 10px;
                border-radius: 15px;
                width: 150px;
                img {
                  width: 100%;
                  overflow: hidden;
                  border-radius: 5px;
                }
                h2 {
                  font-style: normal;
                  font-weight: 350;
                  font-size: 15px;
                  display: flex;
                  line-height: 17px;
                  color: #071d33;
                  text-align: left;
                  font-family: "gL" !important;
                }
              }
            }
          }
        }
      }

      .support {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        color: #fff;
        font-style: normal;
        font-weight: 325;
        font-size: 13px;
        background: #071d33;
        padding-bottom: 20px;
        border-radius: 14px;
        a {
          border: 1px solid #218ffc;
          color: #fff;
          border-radius: 10px;
          font-size: 11px;
          margin-left: 20px;
          padding: 20px 20px 15px;
          font-family: "g";
          text-decoration: none;
        }
      }
    }
  }

  .main-login-right {
    padding: 80px;
    .menu {
      ul {
        padding: 0;
        margin: 0;
        li {
          display: inline-flex;
          margin: 20px;
          margin-top: 0;
          color: #071d33;
          &:first-child {
            margin-left: 0;
            a {
              color: #071d33;
              text-decoration: none;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
    .title {
      color: #071d33;
      font-size: 40px;
      line-height: 48px;
      margin: 50px 0 0;
      font-weight: bold;
    }
    .sub-title {
      color: #071d33;
      font-size: 20px;
      margin-bottom: 50px;
    }
  }

  .login-page-social-media {
    position: absolute;
    top: 20%;
    right: 0;
    background: #ffffff;
    box-shadow: 0px 0px 12px -2px rgb(33 143 252 / 25%);
    border-radius: 21px 0px 0px 21px;
    padding: 20px;
    z-index: 11111;
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        margin: 5px 0;
        a {
          &:hover {
            img {
              transform: scale(1.2);
            }
          }
          img {
            width: 25px;
            height: 25px;
            transition: transform 0.2s ease;
          }
        }
      }
    }
  }

  .requirement {
    padding: 50px 0;
    margin: 0 auto;
    h2 {
      text-align: center;
      font-weight: 400;
      font-size: 42px;
      line-height: 50px;
      color: #071d33;
    }
    h6 {
      text-align: center;
      font-family: "gL";
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
      color: #071d33;
      opacity: 0.5;
      margin-bottom: 50px;
    }
    .custom_row {
      max-width: 900px;
      margin: 0 auto;
    }
    .thumbnail {
      background-color: #071d33;
      margin: 15px 0;
      width: 100%;
      border-radius: 72px;
      position: relative;
      .wrapper {
        padding: 30px 20px;
        color: #fff;
        img {
          position: absolute;
          left: 15px;
          width: 48px;
          height: 48px;
          top: 15px;
        }
        h5 {
          padding-left: 50px;
          margin: 0;
          font-weight: 350;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
  .makeMoney {
    background-color: #23c123;
    text-align: center;
    border-radius: 40px;
    max-width: 390px;
    padding: 15px 0;
    border-radius: 54px;
    margin: 50px auto 0;
  }

  .makeMoney .downloadAppWrapper {
    display: inline-flex;
    align-items: center;
  }

  .makeMoney img {
    width: 48px;
    height: 48px;
    margin-right: 10px;
  }

  .makeMoney span.title {
    font-size: 16px;
    color: #fff;
  }

  @media (max-width: 991px) {
    .login-info-wrapper {
      min-height: 0 !important;
      padding: 20px 0 !important;
    }

    .news-info {
      display: none !important;
    }

    .support {
      display: none !important;
    }

    .login-info-wrapper h1 {
      margin: 10px 0 !important;
      font-size: 21px !important;
      line-height: 22px !important;
      text-align: center !important;
    }

    .login-info-wrapper h6 {
      display: none !important;
    }

    .main-login-right {
      padding: 10px 25px !important;
    }
  }

  @media (min-width: 768px) and (max-width: 1321px) {
    .left-wrapper {
      .login-info-wrapper {
        .news-info {
          ul {
            li {
              a {
                .thumbnail {
                  width: 125px;
                }
              }
            }
          }
        }
      }
    }
  }

  .login-wrapper-form {
    padding: 50px 25px;
    max-width: 350px;
    .ant-form-item-control-input-content {
      max-width: 100%;
    }
    .forget-password-wrapper {
      text-align: right;
      margin: 0;
      .forget-password {
        box-shadow: none;
        border: none;
      }
    }
    .login-form-button {
      width: 100%;
      margin-top: 50px;
      height: 45px;
    }
  }
}
