.google-auto-compelete {
  &:disabled {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}
.select-state-auto-complete {
  width: 100%;
}
.address-search {
  padding: 20px 0;
}
