.spinner-outer-block {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 72%);
  z-index: 999999;
  .spinner-block {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .spinner-border {
    border-right-color: #fff;
  }
}
