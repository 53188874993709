body {
  margin: 0;
  font-family: 'g',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "gBold";
  src: url("./assets/fonts/Gotham.woff2") format("woff2"),
    url("./assets/fonts/Gotham.woff") format("woff"),
    url("./assets/fonts/Gotham.ttf") format("truetype"),
    url("./assets/fonts/Gotham.svg#Gotham") format("svg");
}

@font-face {
  font-family: "g";
  src: url("./assets/fonts/Gotham-Medium.woff2") format("woff2"),
    url("./assets/fonts/Gotham-Medium.woff") format("woff"),
    url("./assets/fonts/Gotham-Medium.ttf") format("truetype"),
    url("./assets/fonts/Gotham-Medium.svg#Gotham") format("svg");

}

@font-face {
  font-family: "gL";
  src: url("./assets/fonts/Gotham-Light.woff") format("woff"),
    url("./assets/fonts/Gotham-Light.ttf") format("truetype"),
    url("./assets/fonts/Gotham-Light.svg#Gotham") format("svg"),
    url("./assets/fonts/Gotham-Light.otf") format("otf");
}

@font-face {
  font-family: "gR";
  src: url("./assets/fonts/Gotham-Regular.woff") format("woff"),
    url("./assets/fonts/Gotham-Regular.ttf") format("truetype"),
    url("./assets/fonts/Gotham-Regular.svg#Gotham") format("svg"),
}

.whats-app-live-chat{
  position: fixed;
  right: -7px;
  background: transparent;
  border: 0px;
  width: 44px;
  height: 180px;
  cursor: pointer;
  transition: right 0.3s ease; 
  top: 10%;
}
.whats-app-live-chat:hover{
  right: -4px;
}