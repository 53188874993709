@import "../../utils/color/Colors.scss";

.profile-update {
  box-shadow: -3px 0px 4px -4px #000;
  overflow: hidden;
  padding: 0 20px;
  .divider {
    margin-top: 23px;
    span {
      color: #273b5e;
      font-family: "gBold";
      font-size: 25px;
    }
  }
  .title-info {
    font-size: 18px;
  }
  .blue {
    color: $master-blue;
  }
  .green {
    color: $progress-green;
  }
  .ant-card {
    position: relative;
    .ant-card-head {
      font-family: "g";
      font-weight: 100;
    }
    .ant-card-body {
      line-height: 18px;
      color: #000;
      font-family: "gL";
      p {
        margin: 2px 0;
      }
    }
  }
  .avatar-uploader-wrapper {
    .avatar-wrapper {
      position: relative;
      .tooltip-avatar {
        position: absolute;
        cursor: pointer;
        right: 50px;
        img {
          position: absolute;
          width: 180px;
          height: 180px;
          top: -150px;
          border-radius: 100%;
          object-fit: cover;
          right: 0;
          left: 0;
          opacity: 0;
          z-index: 11111;
          visibility: hidden;
        }
        &:hover {
          img {
            opacity: 1;
            visibility: visible;
          }
        }
      }
      .avatar-image {
        margin: 0 auto;
        position: relative;
        width: 170px;
        height: 170px;
        border-radius: 100%;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        input[type="file"] {
          color: white;
          background-color: $master-blue;
          font-size: 11px;
          margin: 0 auto;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px;
          cursor: pointer;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          opacity: 0;
          padding: 10px 20px 20px 20px;
        }
        input[type="file"]::file-selector-button {
          display: none;
        }

        img {
          width: 170px;
          height: 170px;
          border-radius: 100%;
          border: 1px solid #eee;
          object-fit: cover;
        }
      }
    }
  }
  .photo-id-uploader-wrapper {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    max-width: 300px;
    margin: 0 auto;
    z-index: 11;
    .tooltip-photo-id {
      position: absolute;
      cursor: pointer;
      right: -30px;
      top: -20px;
      img {
        position: absolute;
        width: 300px;
        height: 195px;
        top: -200px;
        border-radius: 5px;
        object-fit: cover;
        right: -50px;
        opacity: 0;
        visibility: hidden;
        border: 1px solid #000;
      }
      &:hover {
        img {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .avatar-uploader-photo-id-image {
      margin: 0 auto;
      position: relative;
      width: 100%;
      height: 195px;
      overflow: hidden;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      input[type="file"] {
        color: white;
        background-color: $master-blue;
        font-size: 11px;
        margin: 0 auto;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        padding: 10px 20px 20px 20px;
      }
      input[type="file"]::file-selector-button {
        display: none;
      }

      img {
        width: 100;
        height: 195px;
        border: 1px solid #eee;
        object-fit: cover;
      }
    }
  }
}
