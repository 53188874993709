.personal-detail {
  box-shadow: -3px 0px 4px -4px #000;
  overflow: hidden;
  padding: 0 20px;
  .divider {
    margin-top: 23px;
    margin-bottom: 0px;
    span {
      color: #273b5e;
      font-family: "gBold";
      font-size: 25px;
    }
  }
  .componnet-wrapper-certificate{
    text-align: center;
    margin-bottom: 20px;
    .certificate-img{
      display: block;
      margin: 10px auto;
      width: 70px;
    }
  }
}
