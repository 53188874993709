.legal-detail {
  box-shadow: -3px 0px 4px -4px #000;
  overflow: hidden;
  padding: 0 20px;
  .divider {
    margin-top: 23px;
    margin-bottom: 0px;
    span {
      color: #273b5e;
      font-family: "gBold";
      font-size: 25px;
    }
    &.inside {
      margin-top: 50px;
    }
  }
  .title-info {
    font-size: 18px;
  }
  .componnet-wrapper {
    padding: 10px 0;
  }
  .ant-upload-list-item-actions {
    display: none;
  }
}
